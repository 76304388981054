import React, { useState, useEffect, useCallback } from "react";
import ReactPlayer from "react-player";
import socketIOClient from "socket.io-client";
import PDFViewer from "./components/PDFViewer";
import Modal from "@material-ui/core/Modal";
import ImageGallery from "react-image-gallery";
import "./App.css";
const DEFAULT_URL = "";
let cache;
async function initCache() {
  cache = await caches.open("displayapp");
}
initCache();

let socket;

function App() {
  const [videoUrl, setVideoUrl] = useState(DEFAULT_URL);
  const [pdfUrl, setPdfUrl] = useState();
  const [galleryData, setGalleryData] = useState();
  const [muted, setMuted] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const showScreenSaver = useCallback((url) => {
    setVideoFromCache(url || DEFAULT_URL);
    setMuted(true);
    setPdfUrl();
    setGalleryData();
  }, []);

  const setVideoFromCache = async (url) => {;
    // skip cache for Vimeo URLs due to CORS policy
    if (url?.indexOf && url.indexOf("vimeo.com") !== -1) {
      setVideoUrl(url);
      return;
    }
    const cacheObj = await caches.match(url);
    console.log("**** cacheObj", cacheObj);
    if (!cacheObj) {
      console.log(
        "Cache-miss url. Begin streaming immediately and begin fetch for cache in 3 seconds",
        url
      );
      setVideoUrl(url);
      setTimeout(() => {
        cache.add(url);
      }, 3000);
    } else {
      console.log("Cache-hit for url", url);
      const blob = await cacheObj.blob();
      setTimeout(() => {
        setVideoUrl(URL.createObjectURL(blob));
      }, 500);
    }
  };
  useEffect(() => {
    socket = socketIOClient(process.env.REACT_APP_API_BASE_URL, {
      query: {
        appHost: window.location.host,
      },
      withCredentials: false
    });
    const handler = ({ actionType, urls, pageNumber: num }) => {
      switch (actionType) {
        case "viewBrochure":
          setPdfUrl(urls[0]);
          setGalleryData();
          setVideoUrl();
          break;
        case "changeBrochurePage":
          setPageNumber(num);
          break;
        case "viewImage":
          const data = urls.map((url) => ({ original: url }));
          setGalleryData(data);
          setVideoUrl();
          setPdfUrl();
          break;
        case "viewVideo":
          setMuted(false);
          setVideoFromCache(urls[0]);
          setGalleryData();
          setPdfUrl();
          break;
        case "resetDisplay":
          console.log("*** set screensaver url to", urls[0]);            
          showScreenSaver(urls[0]);
          break;
        default:
      }
    };
    socket.on("broadcast", handler);
    return function cleanup() {
      socket.off("broadcast", handler);
    };
  }, [showScreenSaver]);

  return (
    <div className="app">
      {!pdfUrl && !galleryData && (
        <div className="player-wrapper" style={videoUrl ? {backgroundImage: 'none'} : null}>
          <ReactPlayer
            url={videoUrl}
            height="100%"
            width="100%"
            className="react-player"
            playing
            loop
            volume={100}
            muted={muted}
          />
        </div>
      )}

      <Modal
        open={!!pdfUrl || !!galleryData}
        onClose={showScreenSaver}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <>
          {!!pdfUrl && <PDFViewer url={pdfUrl} pageNumber={pageNumber} />}
          {!!galleryData && (
            <ImageGallery
              items={galleryData}
              showNav={false}
              showThumbnails={false}
              useBrowserFullscreen={false}
              showPlayButton={false}
              showFullscreenButton={false}
              additionalClass="gallery"
            />
          )}
        </>
      </Modal>
    </div>
  );
}

export default App;
