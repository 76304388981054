import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function PDFViewer({ url, pageNumber: numProp }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    console.log("onDocumentLoadSuccess", numPages);
    setNumPages(numPages);
    setPageNumber(1);
  }
  function onDocumentLoadError(e) {
    console.log("onDocumentLoadSuccess error");
  }

  useEffect(() => {
    setPageNumber(numProp)
  }, [numProp])

  return (
    <>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        height={window.innerHeight - 80}
      >
        <Page pageNumber={pageNumber} 
          height={window.innerHeight - 80}
        />
      </Document>
      <div className="pageControl">
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
      </div>
    </>
  );
}
